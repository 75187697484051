import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Container, Button, Stack } from '@material-ui/core';
// Modules
import { PATH_PUBLIC } from 'routes/paths';

import { MHidden } from 'components/@material-extend';

// components
import Logo from 'components/Logo';
import MainFooter from './main/MainFooter';

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 88;
const APP_BAR_DESKTOP = 88;

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  height: APP_BAR_DESKTOP,
  width: '100%',
  position: 'fixed',
  background: '#fff',
  zIndex: 999999,
  // padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    height: APP_BAR_MOBILE
  }
}));
const ContainerStyle = styled((props) => <Container maxWidth={'lg'} {...props} />)(({ theme }) => ({
  width: '100%',
  height: APP_BAR_DESKTOP,

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    alignContent: 'center',
    height: APP_BAR_MOBILE
  }
}));

const ButtonStyles = styled((props) => <Button {...props} />)(({ theme }) => ({
  minWidth: 145,
  padding: '10px 15px',
  fontSize: 15,
  [theme.breakpoints.up('sm')]: {}
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function LandingLayout() {
  return (
    <>
      <HeaderStyle>
        <ContainerStyle>
          <RouterLink to="/" style={{ flexGrow: 1 }}>
            <Logo />
          </RouterLink>
          <MHidden width="mdDown">
            <Stack direction="row" spacing={1} alignItems={'center'}>
              <ButtonStyles variant="contained" component={RouterLink} to={PATH_PUBLIC.public.getStarted}>
                Get Started
              </ButtonStyles>
              {/* <ButtonStyles variant="contained" component={RouterLink} to={PATH_PUBLIC.public.getStarted}>
                Schedule Demo
              </ButtonStyles> */}
            </Stack>
          </MHidden>
        </ContainerStyle>
        <ToolbarShadowStyle />
      </HeaderStyle>
      <Outlet />
      <MainFooter />
    </>
  );
}
