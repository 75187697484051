import { forwardRef } from 'react';
import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ sx }, ref) => {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.grey[900];

  return (
    <Box
      ref={ref}
      sx={{ width: 200, height: 'auto', cursor: 'pointer', ...sx }}
    >
      <svg
        preserveAspectRatio="xMidYMid meet"
        x="0"
        y="0"
        viewBox="0 0 700 300"
        width="80"
        height="80"
      >
        <polygon
          points="378.66 200.4 454.12 200.37 623.96 367.75 548.7 367.98 378.66 200.4"
          fill="#36f"
        />
        <polygon
          points="378.66 199.29 454.12 199.32 623.96 31.94 548.7 31.71 378.66 199.29"
          fill="#36f"
        />
        <path
          d="M478.22,353.84l-33.46,48s-19.2-17.05-39.74-24.32c0,0-12.27-6.18-45.62-8.38,0,0-64.18-7.13-108.46,39.79,0,0-34.47,31.88-34.52,87.18,0,0-1.9,32.09,9.32,58.57,0,0,14.83,46.18,67.75,68.22,0,0,38.3,18.29,95.24,4.68,0,0,24.54-4.82,43.89-18.1l0-102.13,60.18-.08,2.67-.05.08,127.4s-18.69,21.74-46,35.37c0,0-11.37,7-36.27,14.17,0,0-65.17,19.29-125.83,1.1,0,0-91.84-21.35-126.08-117.1S179.83,391.66,190,381s59-74.4,150.91-73.84C340.9,307.19,422.24,301.7,478.22,353.84Z"
          transform="translate(-149.89 -307.02)"
          fill="#333"
        />
        <polygon
          points="378.63 199.98 545.92 367.8 624.11 367.98 417.52 161.09 378.63 199.98"
          fill="#36f"
        />
        <polygon
          points="417.52 238.87 624.11 31.98 546.73 31.35 378.63 199.98 417.52 238.87"
          fill="#36f"
        />
        <path
          d="M805.1,664h-5.55v-2h13.53v2h-5.59v16.27H805.1Z"
          transform="translate(-149.89 -307.02)"
        />
        <path
          d="M830.51,672.19c-.13-2.55-.29-5.61-.27-7.89h-.08c-.62,2.15-1.38,4.42-2.3,7l-3.23,8.86h-1.79l-3-8.7c-.86-2.58-1.59-4.94-2.11-7.11h0c-.06,2.28-.19,5.34-.36,8.08l-.48,7.84h-2.26l1.28-18.28h3l3.12,8.84c.76,2.25,1.38,4.26,1.84,6.16H824c.46-1.85,1.11-3.85,1.93-6.16l3.25-8.84h3l1.14,18.28H831Z"
          transform="translate(-149.89 -307.02)"
        />
      </svg>
    </Box>
  );
});

Logo.propTypes = {
  sx: PropTypes.object
};

export default Logo;
