import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from 'layouts/main';
import LandingLayout from 'layouts/LandingLayout';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
// components
import LoadingScreen from 'components/LoadingScreen';
import NoLogoLayout from 'layouts/NoLogoLayout';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '*',
      element: <NoLogoLayout />,
      children: [
        { path: 'checkout', element: <Checkout /> },
        { path: 'checkout-success', element: <CheckoutSuccess /> },
        { path: 'checkout-error', element: <CheckoutError /> }
      ]
    },
    {
      path: '/landing/',
      element: <LandingLayout />,
      children: [
        { path: 'membership', element: <MembershipLandingPage /> },
        { path: 'vets', element: <VetsLandingPage /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'request-demo', element: <RequestDemoForm /> },
        { path: 'get-started', element: <ContactSalesPage /> },
        { path: 'about', element: <AboutPage /> },
        { path: 'faqs', element: <FaqPage /> },
        { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
        { path: 'schedule-demo', element: <ScheduleDemoPage /> }
      ]
    },
    {
      path: '/checkout/*',
      element: <AchCheckout />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard

// Public Pages
const CheckoutSuccess = Loadable(lazy(() => import('pages/CheckoutSuccess')));
const Checkout = Loadable(lazy(() => import('pages/Checkout')));
const CheckoutError = Loadable(lazy(() => import('pages/CheckoutError')));
const LandingPage = Loadable(lazy(() => import('pages/LandingPage')));
const AboutPage = Loadable(lazy(() => import('pages/AboutPage')));
const FaqPage = Loadable(lazy(() => import('pages/FaqPage')));
const PrivacyPolicyPage = Loadable(lazy(() => import('pages/PrivacyPolicyPage')));
const ScheduleDemoPage = Loadable(lazy(() => import('pages/ScheduleDemoPage')));
const RequestDemoForm = Loadable(lazy(() => import('pages/RequestDemo')));
const ContactSalesPage = Loadable(lazy(() => import('pages/ContactSalesPage')));
const MembershipLandingPage = Loadable(lazy(() => import('pages/MembershipLandingPage')));
const VetsLandingPage = Loadable(lazy(() => import('pages/VetsLandingPage')));
const AchCheckout = Loadable(lazy(() => import('pages/AchCheckout')));

const NotFound = Loadable(lazy(() => import('pages/Page404')));
