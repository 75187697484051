import { Icon } from '@iconify/react';

import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack } from '@material-ui/core';
//
import Logo from 'components/Logo';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Learn',
    children: [{ name: 'FAQs', href: '/faqs' }]
  },

  {
    headline: 'Legal',
    children: [{ name: 'Privacy Policy', href: '/privacy-policy' }]
  },
  {
    headline: 'Contact',
    children: [
      // { name: '(800) 757-8234', href: '#' },
      { name: `start@giantkiller.com`, href: 'mailto:start@giantkiller.com', isEmail: true }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[900],
  color: '#b3b3b3'
}));

const CustomLink = styled('a')(({ theme }) => ({
  color: 'rgb(179, 179, 179)',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline'
  }
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth sx={{ width: '100%' }}>
              <img src="/static/Logo-Dark.png" height="30" width="169" />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={4}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Unlock the true power of your brand and take back control of your platform with a Custom Mobile app built
              by GK.
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={10} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline2">
                      {headline}
                    </Typography>
                    {children.map((link) => {
                      if (link.isEmail) {
                        return (
                          <CustomLink key={link.name} href={link.href}>
                            {link.name}
                          </CustomLink>
                        );
                      } else {
                        return (
                          <Link
                            to={link.href}
                            key={link.name}
                            color="inherit"
                            variant="body2"
                            component={RouterLink}
                            sx={{ display: 'block' }}
                          >
                            {link.name}
                          </Link>
                        );
                      }
                    })}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          Giant Killer © 2021. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
